<template>
	<div class="washNormal">
		<!-- 材料导航 -->
		<material-banner :menuIndex="6"></material-banner>
		
		<!-- 产品特点 -->
		<div class="region-com region-trait k_flex_column_jcc_aic">
			<div class="region-title k_flex_column_jcc_aic">
				<span class="region-title-main">{{ $t('m.washNormalProductFeatures') }}</span>
				<span class="region-title-deputy">{{ $t('m.washNormalNormalTemperature') }}</span>
			</div>
			<div class="region-desc k_flex_wrap_jcsb">
				<div v-for="(item,index) in traitList" class="region-desc-single k_flex" :key="index" :class="index>0?'normal':'special'">
					<img v-if="index<4" :src="washNormalUrl+item.img" class="region-desc-left"/>
					<div v-if="index<4" class="region-desc-right k_flex_column_aifs">
						<span>{{item.title}}</span>
						<span>{{item.content}}</span>
						<div v-if="item.special_img01" class="k_flex region-desc-right-bottom">
							<img :src="washNormalUrl+item.special_img01"/>
							<img :src="washNormalUrl+item.special_img02"/>
						</div>
					</div>
					<div v-if="index>=4" class="k_flex_jcc_aic region-desc-special">
						{{ $t('m.washNormaltraitListFourmajorcharacteristics') }}<br />
						{{ $t('m.washNormaltraitListLetyouchooseus') }}
					</div>
				</div>
			</div>
		</div>
		
		<!-- 节约成本·降低排废 -->
		<div class="region-com region-save k_flex_column_jcc_aic">
			<div class="region-title k_flex_column_jcc_aic">
				<span class="region-title-main">{{ $t('m.washNormaltraitListCostsaving') }}</span>
				<span class="region-title-deputy">{{ $t('m.washNormaltraitListIntraditional') }}</span>
			</div>
			<div class="region-desc k_flex_wrap_jcc">
				<div class="region-desc-whole k_flex_jcsa_aic">
					<div v-for="(item,index) in saveList" class="region-desc-single k_flex_column_aic" :key="index">
						<span class="region-desc-single-title k_flex_jcc_aic">{{item.title}}</span>
						<img :src="washNormalUrl+item.img"/>
						<span class="region-desc-single-content k_flex_jcc_aic">{{item.content}}</span>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 产品展示 -->
		<div class="region-com region-display k_flex_column_jcc_aic">
			<div class="region-title k_flex_column_jcc_aic">
				<span class="region-title-main">{{ $t('m.washNormaltraitListProducts') }}</span>
				<span class="region-title-deputy">{{ $t('m.washNormaltraitListYouridealwashlabel') }}</span>
			</div>
			<div class="region-desc k_flex_jcsb_aic">
				<img :src="washNormalUrl+$t('m.washNormaldisplay1')" class="region-desc-left"/>
				<div class="region-desc-right k_flex_column_jcsb_aic">
					<img :src="washNormalUrl+$t('m.washNormaldisplay2')" class="region-desc-right-top"/>
					<img :src="washNormalUrl+$t('m.washNormaldisplay3')" class="region-desc-right-bottom"/>
				</div>
			</div>
		</div>
		
		<!-- 适用行业 -->
		<div class="region-com region-suitable k_flex_column_jcc_aic">
			<div class="region-title k_flex_column_jcc_aic">
				<span class="region-title-main">{{ $t('m.washNormaltraitListApplicableindustries') }}</span>
				<span class="region-title-deputy">{{ $t('m.washNormaltraitListMeettheneeds') }}</span>
			</div>
			<div class="region-desc k_flex_wrap_jcc">
				<div class="region-desc-single k_flex" v-for="(item,index) in suitableList" :key="index">
					<img class="region-desc-single-left" :src="washNormalUrl+item.img" />
					<div class="region-desc-single-right k_flex_column_aifs">
						<span>{{item.title}}</span>
						<span></span>
						<span>{{item.content}}</span>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 产品规格 -->
		<div class="region-com region-size k_flex_column_jcc_aic">
			<div class="region-title k_flex_column_jcc_aic">
				<span class="region-title-main">{{$t('m.TechnicalSpecification')}}</span>
			</div>
			<div class="region-cont">
				<el-table
					:data="tableData"
					:span-method="objectSpanMethod"
					border
					style="width: 100%;font-size: 16px;">
					<el-table-column
						prop="name"
						label="产品名称"
						align="center"
						width="140">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Product')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="type"
						label="产品型号"
						align="center"
						width="140">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Model')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="standards"
						label="产品规格"
						align="center"
						width="160">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Specification')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="metre"
						label="米数"
						align="center"
						width="140">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Length')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="introduce"
						label="介绍"
						align="center"
						width="599">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Introduction')}}</div>
						</template>
						<template slot-scope="scope">
							<div class="region-cont-introduce">
								<div class="region-cont-introduce-single" v-for="(item,index) in scope.row.introduce">
									<span>{{item.title}}</span>
									<span>{{item.content}}</span>
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<img v-if="false" :src="washNormalUrl+'size.png'" class="region-desc">
		</div>
	</div>
</template>

<script>
import materialBanner from '@c/materialBanner/materialBanner'
export default{
	components:{
		materialBanner,
	},
	data() {
		return {
			// suitableList:[
			// 	{
			// 		title:'家居纺织',
			// 		img:'suitable_01.png',
			// 		content:'可以用作家居生活品，比如沙发藏标、床单被罩 、浴巾毛巾之类的标签，可以长久保存。'
			// 	},
			// 	{
			// 		title:'箱包标签',
			// 		img:'suitable_02.png',
			// 		content:'用作箱包等不需要高温熨烫的货品上'
			// 	},
			// 	{
			// 		title:'包装',
			// 		img:'suitable_03.png',
			// 		content:'米面粮食等包装封口标，长时间保存无需碳带'
			// 	},
			// 	{
			// 		title:'电子标签',
			// 		img:'suitable_04.png',
			// 		content:'各种缠绕带线标'
			// 	}
			// ],
			// tableData: [
			// 	{
			// 		name: '热敏常温水洗唛',
			// 		type: 'Tht003',
			// 		standards: '1085mm',
			// 		metre: '3000m',
			// 		introduce: [
			// 			{
			// 				title:'耐水洗：',
			// 				content:'经过40°常温水洗，60分钟不褪色，不会脱层；'
			// 			},
			// 			{
			// 				title:'耐刮擦：',
			// 				content:'干摩擦色牢度4级以上；'
			// 			},
			// 			{
			// 				title:'长久保存：',
			// 				content:'团队经过长时间研发的可长久保存的热敏材料，十年仍不褪色；'
			// 			},
			// 			{
			// 				title:'显色浓度高字迹清晰：',
			// 				content:'热敏打印出来显色浓度高字迹清晰可见；'
			// 			},
			// 			{
			// 				title:'节约成本降低排废：',
			// 				content:'传统碳带打印的需要不定时更换碳带，增加成本。相对之下热敏打印不仅节约成本更是环保首选；'
			// 			},
			// 			{
			// 				title:'防酒精：',
			// 				content:'可浸泡在酒精中超过5分钟不褪色；'
			// 			}
			// 		]
			// 	}, 
			// 	{
			// 		name: '',
			// 		type: '',
			// 		standards: '1090mm',
			// 		metre: '6000m',
			// 		introduce: []
			// 	}
			// ],
			// traitList:[
			// 	{
			// 		title:'耐水洗',
			// 		content:'经过40°常温水洗，60分钟不褪色，不会脱层 ',
			// 		special_img01:'trait_logo01.jpg',
			// 		img:'trait_01.jpg',
			// 		special_img02:'trait_logo02.jpg'
			// 	},
			// 	{
			// 		title:'耐刮擦',
			// 		content:'干摩擦色牢度4级以上',
			// 		img:'trait_02.jpg'
			// 	},
			// 	{
			// 		title:'长久保存',
			// 		content:'团队经过长时间研发的可长久保存的热敏材料，十年仍不褪色',
			// 		img:'trait_03.jpg'
			// 	},
			// 	{
			// 		title:'防酒精',
			// 		content:'可浸泡在酒精中超过5分钟不褪色',
			// 		img:'trait_04.jpg'
			// 	},
			// 	{
			// 		title:'四大特色让您选择我们',				
			// 	}
			// ],
			// saveList:[
			// 	{
			// 		title:'传统碳带打印',
			// 		content:'传统碳带-操作繁琐、不环保、限制多',
			// 		img:'save_01.jpg'
			// 	},
			// 	{
			// 		title:'热敏打印无需碳带',
			// 		content:'热敏打印-无需碳带、打印成本低、环保、色浓度高字迹清晰',
			// 		img:'save_02.jpg'
			// 	}
			// ]
		}
	},
	computed: {
		washNormalUrl() {
		  return 'https://oss-www.kymasf.com/img/material/washNormal/'
		},
		traitList(){
			return [
				{
					title:this.$t('m.washNormaltraitListWashable'),
					content:this.$t('m.washNormaltraitListNocolourfading'),
					special_img01:'trait_logo01.jpg',
					img:'trait_01.jpg',
					special_img02:'trait_logo02.jpg'
				},
				{
					title:this.$t('m.washNormaltraitListScratchResistant'),
					content:this.$t('m.washNormaltraitListcolorfastness'),
					img:'trait_02.jpg'
				},
				{
					title:this.$t('m.washNormaltraitListLongshelflife'),
					content:this.$t('m.washNormaltraitListteamdeveloped'),
					img:'trait_03.jpg'
				},
				{
					title:this.$t('m.washNormaltraitListAlcoholresistant'),
					content:this.$t('m.washNormaltraitListnocolorfading'),
					img:'trait_04.jpg'
				},
				{
					title:'四大特色让您选择我们',				
				}
			]
		},
		saveList(){
			return[
				{
					title:this.$t('m.washNormaltraitListTraditionalribbonprinting'),
					content:this.$t('m.washNormaltraitListwashNormaltraitList'),
					img:'save_01.jpg'
				},
				{
					title:this.$t('m.washNormaltraitListThermalprinting'),
					content:this.$t('m.washNormaltraitListnoribbon'),
					img:'save_02.jpg'
				}
			]
		},
		suitableList(){
			return [
				{
					title:this.$t('m.washNormaltraitListHomeTextile'),
					img:'suitable_01.png',
					content:this.$t('m.washNormaltraitListItcanbeused')
				},
				{
					title:this.$t('m.washNormaltraitListLuggage'),
					img:'suitable_02.png',
					content:this.$t('m.washNormaltraitListUsedongoods')
				},
				{
					title:this.$t('m.washNormaltraitListPackaging'),
					img:'suitable_03.png',
					content:this.$t('m.washNormaltraitListPackagingsealing')
				},
				{
					title:this.$t('m.washNormaltraitListtag'),
					img:'suitable_04.png',
					content:this.$t('m.washNormaltraitListLabelsfor')
				}
			]
		},
		tableData(){
			return [
				{
					name: this.$t('m.NormalWashLabel'),
					type: 'Tht003',
					standards: '1085mm',
					metre: '3000m',
					introduce: [
						{
							title:this.$t('m.washNormaltraitListWashabletitle'),
							content:this.$t('m.washNormaltraitListWashableNocolour'),
						},
						{
							title:this.$t('m.washNormaltraitListScratchResistanttitle'),
							content:this.$t('m.washNormaltraitListScratchResistant'),
						},
						{
							title:this.$t('m.washNormaltraitListLongshelflifetitle'),
							content:this.$t('m.washNormaltraitListLongshelflife'),
						},
						{
							title:this.$t('m.washNormaltraitListHighcolortitle'),
							content:this.$t('m.washNormaltraitListHighcolor')
						},
						{
							title:this.$t('m.washNormaltraitListCostsavingandwastetitle'),
							content:this.$t('m.washNormaltraitListCostsavingandwaste')
						},
						{
							title:this.$t('m.washNormaltraitListAlcoholresistanttitle'),
							content:this.$t('m.washNormaltraitListAlcoholresistant')
						}
					]
				}, 
				{
					name: '',
					type: '',
					standards: '1090mm',
					metre: '6000m',
					introduce: []
				}
			]
		} 
	},
	mounted() {
		
	},
	methods: {
		objectSpanMethod({ row, column, rowIndex, columnIndex }) {
			if (columnIndex === 0 || columnIndex === 1 || columnIndex === 4) {
				if (rowIndex % 4 === 0) {
					return {
						rowspan: 4,
						colspan: 1
					};
				} else {
					return {
						rowspan: 0,
						colspan: 0
					};
				}
			}
		}
	}
}
</script>

<style lang="less" scoped>
.washNormal{
	width: 100%;
	height: 100%;
	background: #fff;
	// 区域公共样式
	.region-com{
		width: 100%;
		.region-title{
			.region-title-main{
				margin-top: 48px;
				height: 48px;
				font-size: 42px;
				font-family: Adobe Heiti Std;
				font-weight: normal;
				color: #2E3136;
				line-height: 48px;
				// border-bottom: 2px dashed #535353;
				// padding-bottom: 15px;
				// margin-bottom: 15px
				margin: 0;
				padding: 0;
				border: none;
			}
			.region-title-deputy{
				width: 981px;
				font-size: 20px;
				margin-top: 10px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #2C3F64;
				line-height: 24px;
				text-align: center;
			}
		}
	}
	
	//产品特点
	.region-trait{
		.region-title{
			margin-top: 36px;
		}
		.region-desc{
			width: 1200px;
			// padding: 46px 144px;
			// height: 362px;
			// background: #257FDB;
			margin: 34px 0 110px 0;
			box-sizing: border-box;
			.region-desc-single{
				padding: 12px;
				box-sizing: border-box;
				background: #44608B;
			}
			.normal{
				width: 593px;
				margin-top: 14px;
				.region-desc-right{
					padding: 0 24px 0 24px;
					span{
						&:nth-child(1){
							font-size: 30px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #FFFFFF;
							margin-bottom: 17px;
						}
						&:nth-child(2){
							font-size: 22px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #FFFFFF;
						}
					}
				}
				.region-desc-special{
					width: 100%;
					height: 100%;
					font-size: 45px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #FFFFFF;
					line-height: 56px;
					text-align: center;
				}
			}
			.special{
				width: 1200px;
				.region-desc-right{
					padding: 72px 24px 0 30px;
					position: relative;
					span{
						&:nth-child(1){
							font-size: 30px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #FFFFFF;
							margin-bottom: 17px;
						}
						&:nth-child(n+2){
							font-size: 22px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #FFFFFF;
						}
					}
					.region-desc-right-bottom{
						position: absolute;
						bottom: 0px;
						// margin-left: 18px;
						img{
							margin-right: 18px;
						}
					}
				}
			}
		}
	}
	
	// 节约成本·降低排废
	.region-save{
		.region-title{
			.region-title-main{
				margin-top: 48px;
				height: 48px;
				font-size: 42px;
				font-family: Microsoft YaHei;
				font-weight: normal;
				color: #2E3136;
				line-height: 48px;
				font-weight: bold;
				// border-bottom: 2px dashed #535353;
				// padding-bottom: 15px;
				// margin-bottom: 15px
				margin: 0;
				padding: 0;
				border: none;
			}
			.region-title-deputy{
				width: 981px;
				font-size: 22px;
				margin-top: 20px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				line-height: 24px;
				text-align: center;
				color: #2E3136;
			}
		}
		.region-desc{
			margin-top: 308px;
			width: 1200px;
			height: 495px;
			background: #44608B;
			position: relative;
			margin-bottom: 71px;
			.region-desc-whole{
				width: 100%;
				position: absolute;
				bottom: 0;
				.region-desc-single{
					.region-desc-single-title{
						width: 485px;
						height: 66px;
						background: #3F7287;
						border-radius: 33px;
						font-size: 24px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						margin-bottom: 29px;
						overflow:hidden;
  					white-space: nowrap;
  					text-overflow: ellipsis;
					}
					.region-desc-single-content{
						width: 485px;
						height: 108px;
						font-size: 24px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						text-align: center;
					}
				}
			}
		}
	}
	
	//产品展示
	.region-display{
		background: #EFF1F2;
		.region-title{
			margin-top: 55px;
		}
		.region-cont{
			
		}
		.region-desc{
			width: 1200px;
			margin-bottom: 66px;
			margin-top: 45px;
			.region-desc-right{
				height: 780px;
			}
		}
	}
	
	//适用行业
	.region-suitable{
		margin: 64px 0 57px 0;
		.region-title{
			
		}
		.region-cont{
			
		}
		.region-desc{
			width: 1200px;
			margin-top: 40px;
			.region-desc-single{
				width: 585px;
				height: 200px;
				background: #4E71D3;
				box-sizing: border-box;
				padding: 25px 32px 25px 28px;
				margin-bottom: 30px;
				&:nth-child(2n){
					// margin-right: 40px;
				}
				&:nth-child(2n+1){
					margin-right: 30px;
				}
				&:nth-child(n+3){
					margin-bottom: 0px;
				}
				.region-desc-single-left{
					width: 150px;
					height: 150px;
					border-radius: 50%;
					margin-right: 24px;
				}
				.region-desc-single-right{
					width: 351px;
					box-sizing: border-box;
					padding: 8px 0 0 0;
					span{
						&:nth-child(1){
							font-size: 38px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #FFFFFF;
							line-height: 48px;
						}
						&:nth-child(2){
							width: 52px;
							height: 10px;
							margin: 16px 0;
							background: #FFFFFF;
						}
						&:nth-child(3){
							font-size: 16px;
							font-family: Adobe Heiti Std;
							font-weight: normal;
							color: #FFFFFF;
							line-height: 18px;
						}
					}
				}
			}
		}
	}
	
	//产品规格
	.region-size{
		background: #EFF1F2;
		.region-title{
			margin-top: 40px;
		}
		.region-cont{
			margin-top: 30px;
			width: 1200px;
			background: #B7C4EA;
			padding: 10px;
			border-radius: 8px;
			box-sizing: border-box;
			font-size: 16px;
			margin-bottom: 56px;
			.region-cont-introduce{
				.region-cont-introduce-single{
					font-size: 16px;
					text-align: left;
				}
			}
		}
		.region-desc{
			width: 1200px;
			margin-top: 20px;
			margin-bottom: 56px;
		}
	}
}
</style>